import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid, {GridProps} from '@material-ui/core/Grid'

const styles = {
  grid: {
    padding: '0 15px !important'
  }
}

const useStyles = makeStyles(styles)

type Props = React.HTMLAttributes<HTMLDivElement> & GridProps

const GridItem: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles()
  const { children, ...rest } = props
  return (
    <Grid item {...rest} className={classes.grid}>
      {children}
    </Grid>
  )
}

export default GridItem
