import {
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 320,
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  formField: {
    width: 270,
  }
}))

const LoginView: React.FunctionComponent = () => {
  const classes = useStyles()
  const history = useHistory()

  const goToList = () => history.push('/admin/list')
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Maximum Endurance Events
        </Typography>
        <form noValidate autoComplete="off"  className={classes.formField} onSubmit={goToList}>
          <TextField label="Username" className={classes.formField} />
          <TextField label="Password" type="password" className={classes.formField} />
        </form>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={goToList}>Sign In</Button>
      </CardActions>
    </Card>
  )
}

export default LoginView