import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(styles as any)

type Props = React.HTMLAttributes<HTMLDivElement> & {
  plain?: string,
  profile?: boolean,
}

const CardBody: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles()
  const { className, children, plain, profile, ...rest } = props
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyProfile]: profile,
    [className!]: className !== undefined
  })
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  )
}

export default CardBody