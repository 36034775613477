import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid, { GridProps } from '@material-ui/core/Grid'

const styles = {
  grid: {
    margin: '0 -15px !important',
    width: 'unset'
  }
}

const useStyles = makeStyles(styles)

type Props = React.HTMLAttributes<HTMLDivElement> & GridProps

const GridContainer: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles()
  const { children, ...rest } = props
  return (
    <Grid container {...rest} className={classes.grid}>
      {children}
    </Grid>
  )
}

export default GridContainer