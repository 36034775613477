import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../assets/jss/material-dashboard-react/components/cardFooterStyle'

const useStyles = makeStyles(styles)

type Props = React.HTMLAttributes<HTMLDivElement> & {
  plain?: boolean,
  profile?: boolean,
  stats?: boolean,
  chart?: boolean,
}

const CardFooter: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles()
  const { className, children, plain, profile, stats, chart, ...rest } = props
  const cardFooterClasses = classNames({
    [classes.cardFooter]: true,
    [classes.cardFooterPlain]: plain,
    [classes.cardFooterProfile]: profile,
    [classes.cardFooterStats]: stats,
    [classes.cardFooterChart]: chart,
    [className!]: className !== undefined
  })
  return (
    <div className={cardFooterClasses} {...rest}>
      {children}
    </div>
  )
}

export default CardFooter