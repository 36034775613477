/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard'
import LoginIcon from '@material-ui/icons/VpnKey'

// core components/views for Admin layout
import LoginView from './views/Login/Login'
import DashboardView from './views/Dashboard/Dashboard'
import ListView from './views/List/List'

const dashboardRoutes = [
  {
    path: '/login',
    name: 'Login',
    icon: LoginIcon,
    component: LoginView,
    layout: '/naked'
  },
  {
    path: '/dashboard/:masterEventId',
    name: 'Dashboard',
    icon: Dashboard,
    component: DashboardView,
    layout: '/admin'
  },
  {
    path: '/list',
    name: 'All Master Events',
    icon: Dashboard,
    component: ListView,
    layout: '/admin'
  },

]

export default dashboardRoutes
