import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
import { makeStyles, StyledComponentProps } from '@material-ui/core/styles';
import Button, {ButtonProps} from '@material-ui/core/Button';
import styles from '../../assets/jss/material-dashboard-react/components/buttonStyle';

const useStyles = makeStyles(styles as any);

type Props = Omit<ButtonProps, 'color'> & StyledComponentProps & {
  simple?: boolean,
  round?: boolean,
  block?: boolean,
  link?: boolean,
  justIcon?: boolean,
  muiClasses?: any,
  color?: 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'rose' | 'white' | 'transparent',
  target?: string,
}

const RegularButton: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const {
    color,
    round,
    children,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size!]]: size,
    [classes[color!]]: color,
    [classes.round]: round,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className!]: className
  });
  return (
    <Button {...rest} classes={muiClasses} className={btnClasses}>
      {children}
    </Button>
  );
}

export default RegularButton