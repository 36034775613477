import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../assets/jss/material-dashboard-react/components/cardHeaderStyle'

const useStyles = makeStyles(styles as any)

type Props = React.HTMLAttributes<HTMLDivElement> & {
  plain?: boolean,
  profile?: boolean,
  stats?: boolean,
  icon?: boolean,
  color?: 'warning' | 'success' | 'danger' | 'info' | 'primary' | 'rose'
}

const CardHeader: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles()
  const { className, children, color, plain, stats, icon, ...rest } = props
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[color + 'CardHeader']]: color,
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderStats]: stats,
    [classes.cardHeaderIcon]: icon,
    [className!]: className !== undefined
  })
  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  )
}

export default CardHeader