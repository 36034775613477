import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import axios from 'axios'
import TablePaginator from '../../components/TablePaginator'
import { CreateMasterEventResponse } from '../../components/types'

const PAGE_SIZE = 25

type ManifestItem = {
  id: string,
  name: string,
  createDate: number,
  events: {
    id: string,
    name: string,
    date: number
  }[]
}

type Manifest = ManifestItem[]

type ListMasterEventsResult = {
  pageSize: number,
  page: number,
  totalPages: number,
  totalResults: number,
  results: Manifest
}

const ListView: React.FunctionComponent = () => {
  const [fetched, setFetched] = React.useState<boolean>(false)
  const [page, setPage] = React.useState<number>(1)
  const [fetchError, setFetchError] = React.useState<Error>()
  const [masterEvents, setMasterEvents] = React.useState<ListMasterEventsResult>()

  React.useEffect(() => {
    const fetchMasterEvents = async () => {
      try {
        const req = await axios.get<ListMasterEventsResult>(`${process.env.REACT_APP_FAKE_PARTNER_API}/masterevent`)
        setMasterEvents(req.data)
        setFetched(true)
        setFetchError(undefined)
      }
      catch (e) {
        setMasterEvents(undefined)
        setFetched(true)
        if (e.response?.status !== 404) {
          setFetchError(e)
        }
      }
    }
    fetchMasterEvents()
  }, [setMasterEvents, setFetched, page])

  return fetched ? fetchError || !(masterEvents?.totalResults ?? 0) ? (
    <Paper>
      <div style={{padding: 16}}>
        <div style={{float: 'right'}}><CreateNewMasterEventButton /></div>
        No events found.<br />
      </div>
      {fetchError && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}><div>{fetchError.message}</div></AccordionSummary>
          <AccordionDetails><pre>{fetchError.stack}</pre></AccordionDetails>
        </Accordion>
      )}
    </Paper>
  ) : (
    <Paper>
      <ResultsView results={masterEvents!} onPageChange={(page) => setPage(page)} />
    </Paper>
  ) : (
    <Paper>
      Fetching...
    </Paper>
  )
}

export default ListView

const CreateNewMasterEventButton: React.FunctionComponent = () => {
  const evCountField = React.useRef<HTMLInputElement>()
  const createMasterEvent = async () => {
    const createReq = await axios.post<CreateMasterEventResponse>(`${process.env.REACT_APP_FAKE_PARTNER_API}/masterevent`, {
      numEventsToGenerate: Number(evCountField.current?.value) ?? 1
    })
    if (createReq.data.identifier) {
      window.location.reload()
    }
  }
  return <div>
    <Button variant="contained" color="primary" onClick={createMasterEvent}>Create New Master Event</Button>
    <TextField inputRef={evCountField} type="number" placeholder="1" style={{width: 25}} />
  </div>
}

type TableProps = {
  results: ListMasterEventsResult,
  onPageChange: (page: number) => void
}
const useStyles = makeStyles({
  table: {
  },
})

const ResultsView: React.FunctionComponent<TableProps> = ({results, onPageChange}) => {
  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <div style={{padding: 16}}>
        <div style={{float: 'right'}}><CreateNewMasterEventButton /></div>
        <Typography variant="h2">Master Events</Typography>
        <Typography variant="h6">Total Events {results?.totalResults ?? 0}</Typography>
      </div>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Master Event Name</TableCell>
            <TableCell align="right">Date Created</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results?.results?.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                <div>{row.name}</div>
                <hr/>
                <ul>
                  {row.events?.map((ev) => (
                    <li key={ev.id}><a href={`/admin/dashboard/${row.id}?eventId=${ev.id}`}>{ev.name}</a></li>
                  ))}
                </ul>
              </TableCell>
              <TableCell align="right">{new Date(row.createDate).toISOString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePaginator
        count={results?.totalResults ?? 0}
        page={results?.page ?? 0}
        rowsPerPage={PAGE_SIZE}
        onChangePage={(_, page) => onPageChange(page)}
      />
    </TableContainer>
  )
}
